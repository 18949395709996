import React, { useEffect, useState } from 'react'
import Footer from '../Components/Footer'
import TagSlogan from '../Components/TagSlogan'
import { Box, Divider, IconButton } from '@mui/material'

import ButtonApp, { ButtonAppHightLight, IconButtonApp } from '../Components/Button'

import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Components/Logo'
import LinkNav from '../Components/LinkNav'
import { TypingText } from '../Components/TypingText'

export default function Home() {
  useEffect(() => {
    window.onscroll = function (e) {
      if (window.pageYOffset > 100) {
        document.getElementById('navbar')?.classList.add("change-header");
      } else {
        document.getElementById('navbar')?.classList.remove("change-header");

      }
      console.log()
    };

  }, [])


  const [show, setShow] = useState(false)
  return (
    <div style={{ position: 'relative' }}>
      <div id='navbar' className='header' style={{ position: 'fixed', left: 0, right: 0, top: '0', padding: '25px 0px', alignItems: 'center', zIndex: 99 }}>
        <div className='container'>
          <div className="wrapper">
            <div style={{ display: 'flex', justifyContent: 'space-between', background: 'transparent' }}>
              <div className='navbar-left'>
                <Logo />
                <LinkNav href='https://app.sishi.finance/option-trading' text='Option Trading' />
                <LinkNav href='https://app.sishi.finance/referral' text='Referral' />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 }}>
                <a href='https://app.sishi.finance/' target='_blank'>
                  <ButtonAppHightLight>
                    Open App
                  </ButtonAppHightLight>
                </a>
                <div className='menu-select' onClick={() => setShow(!show)}>
                  <button className='button-icon transparent button square-medium'>
                    <MenuIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>

        <div className='part-welcome'>
          <div className='container'>
            <div className="wrapper">
              <div style={{
                padding: '110px 0px 20px 0px'
              }}>
                <h1 className='primary-content'>
                  Innovative binary options trading on Arbitrum
                  <br />
                  <Box sx={{
                    "&:after": {
                      content: `""`,
                      border:"solid 2px",
                      height:"90%",
                      animation: "flash 1s infinite",
                    },
                  }}>
                    <TypingText texts={["Fast", "Secure", "Decentralized", "Low Fees"]} />
                  </Box>
                </h1>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="text-description">
                    {/* Inspired by Bitcoin, 21 million SISHI was designed to be the first store of value asset on Binance Smart Chain */}
                  </div>
                </div>
                <div className='part-welcome-action'>
                  <a target='_blank' href='https://app.sishi.finance/option-trading' style={{ textDecoration: 'none' }}>
                    <button className='button button-basic decoration'>
                      Trade Now
                    </button>
                  </a>
                  <a target='_blank' href='https://app.sishi.finance/referral?code=803022' style={{ textDecoration: 'none' }}>
                    <button className='button button-basic border-thin'>
                      Join Referral Program
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  )
}
