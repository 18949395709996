import { useEffect, useMemo, useState } from "react"



export const useTypingTextContext = (texts: string[], typingTime = 50, breakTime = 1000) => {
  const [content, setContent] = useState("")




  useEffect(() => {
    let run = true
    setTimeout(async () => {
      const s = (t: number) => new Promise(r => setTimeout(r, t))

      while (run) {
        for (let text of texts) {
          const splited = text.split("")
          if (run) for (let index in splited) {
            if (run) {
              await s(typingTime)
              setContent(text.slice(0, 1 + +index))
            } else { break; }
          } else break;
          await s(breakTime)
          if (run) for (let index in splited) {
            if (run) {
              await s(typingTime)
              setContent(text.slice(0, splited.length - (1 + +index)) )
            } else { break; }
          } else break;
          await s(breakTime)
        }
      }
    }, 0);


    return () => { run = false };
  }, [JSON.stringify(texts)])

  return content
}


export const TypingText = ({ texts }: { texts: string[] }) => {

  const content = useTypingTextContext(useMemo(() => texts, [JSON.stringify(texts)]))

  return <>{content}</>
}