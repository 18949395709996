import { Divider } from '@mui/material'
import React from 'react'

export default function index() {
  return (

    <div className='container'>
      <div className="wrapper">
        <Divider />
        <div className='bottom-block-content'>
          <div className='block-media'>
            <a href='https://twitter.com/0xsishi' target='_blank'>
              <button className='border-thin button-basic button-fix button'>
                Twitter
              </button>
            </a>
            <a href='https://t.me/sishi_finance' target='_blank'>
              <button className='border-thin button-basic button-fix button'>
                Telegram
              </button>
            </a>
            <a href='https://0xsishi.medium.com/' target='_blank'>
              <button className='border-thin button-basic button-fix button'>
                Medium
              </button>
            </a>
            <a href='https://discord.com/invite/aGQsMxmZbK' target='_blank'>
              <button className='border-thin button-basic button-fix button'>
                Discord
              </button>
            </a>
          </div>

          <a href='https://app.sishi.finance/staking' target='_blank'>
            <button className='button-basic button-fix button-special button'>
              Open App
            </button>
          </a>

        </div>
      </div>
    </div>


  )
}
