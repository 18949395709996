import { Button, IconButton } from '@mui/material'
import React from 'react'

export default function ButtonApp({ children }: { children?: React.ReactNode }) {
  return (
    <button className='button button-basic'>
      {children}
    </button>
  )
}

export function ButtonAppHightLight({ children }: { children?: React.ReactNode }) {
  return (
    <button className='button button-basic highlight'>
      {children}
    </button>
  )
}

export function IconButtonApp({ children }: { children?: React.ReactNode }) {
  return (
    <div style={{ display: 'flex' }}>
      <button className='button-icon button square-medium'>
        {children}
      </button>
    </div>
  )
}
