import React from 'react'

export default function Logo() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <a href='/' style={{display:'contents'}}>
        <img className='square-small' src='/favicon.png'></img>
        <span className='text-logo'>SISHI</span>
      </a>
    </div>
  )
}
